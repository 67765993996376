module.exports = {
  siteTitle: 'Corey Waldrop',
  siteDescription: `The personal site for a very passionate and talented web developer named Corey!`,
  keywords: [
    'web-design',
    'development',
    'artificial intelligence',
    'javascript',
    'nodejs',
    'typescript',
    'react',
    'java',
    'angular',
    'microservice',
    'curriculum',
  ],
  authorName: 'Corey Waldrop',
  twitterUsername: 'WaldropCorey',
  facebookUsername: 'corey.waldrop',
  githubUsername: 'rad10head7',
  authorAvatar: '/images/me3.jpg',
  authorDescription: `I'm a Developer and Software Engineer, deeply passionate about AI, RESTful APIs, reactive front-end development and event driven architecture. I have a constant drive to learn new programming languages, frameworks, and technologies. <br />
    After graduating from college in 2008, I enlisted in the U.S. Navy, where I began working on embedded systems. This sparked my passion for software engineering, particularly in C programming and the Linux Kernel. By the time I left the Navy in 2012, my interests had expanded to encompass back-end server development, DevOps, and modern web solutions. <br /> 
    Following my service, I started freelancing while working full-time at a web development company in San Diego. Over the years, my enthusiasm for technology has only grown, continually renewed by new innovations like Node.js, containerization, microservices, and AI. Every advancement excites me and inspires my desire to keep learning and evolving. <br /> 
    Today, my focus is on developing RESTful APIs, building reactive front-end applications (particularly with Angular, RxJS, and React), containerized application deployment, microservice architectures, and integrating AI into modern web apps and data-driven platforms.
    Currently, I work as a full-stack developer specializing in <strong>TypeScript, Java, Python, Node.js, Linux, functional reactive programming, object-oriented design, and microservices.</strong>`,
  test: `Developer and Software Engineer. Very passionate about REST API and React front-end development in particular. Always interested in learning new programming languages, libraries and frameworks.<br />
    In 2008, after college, I enlisted in the U.S. Navy where I started working on embedded systems. It was there I began to develop a passion and deep understanding for software development and computer science, with a particular interest in the C language and the Linux Kernel. <br /> 
    In 2012, I left the Navy and began to study web development and design, beginning with PHP and basic HTML/CSS/Javascript. It was here I developed a new passion for back-end server development, Python and Networking as a whole. I also began my freelance web design career, while working full time for EDGE Web Solutions in San Diego. In 2016 I was introduced to Nodejs and my passion was revamped yet again! Now my focus has been on the development of RESTful APIs, front-ends built with Reactive progemming techniques (Angular with Rxjs and React), Microservices and Containerized Application Deployment and web-based data science. <br />
    I currently work as a full stack developer specializing in <strong>Typescript, Java, Python, NodeJS, Linux, Functional Reactive Programming, Object Oriented Programming and Microservice Development.</strong>`,
  skills: [
    {
      name: 'Typescript/Javascript',
      level: 95,
    },
    {
      name: 'NodeJS',
      level: 70,
    },
    {
      name: 'Java',
      level: 75,
    },
    {
      name: 'Python',
      level: 80,
    },
    {
      name: 'React',
      level: 80,
    },
    {
      name: 'OpenAI',
      level: 70,
    },
    {
      name: 'Angular',
      level: 90,
    },
    {
      name: 'Gatsby',
      level: 75,
    },
    {
      name: 'NextJs',
      level: 90,
    },
    {
      name: 'Django',
      level: 65,
    },
    {
      name: 'PHP',
      level: 80,
    },
    {
      name: 'Flask',
      level: 45,
    },
    {
      name: 'RestAPI(TypeScript, Java, Python, PHP)',
      level: 75,
    },
    {
      name: 'GraphQl',
      level: 65,
    },
    {
      name: 'CSS/Sass',
      level: 85,
    },
    {
      name: 'HTML5',
      level: 80,
    },
    {
      name: 'Git',
      level: 80,
    },
    {
      name: 'Bash/Zsh',
      level: 90,
    },
    {
      name: 'Linux/Server Admin',
      level: 95,
    },
    {
      name: 'C/C++',
      level: 75,
    },
    {
      name: 'Docker/Kubernetes',
      level: 60,
    },
    {
      name: 'Android',
      level: 45,
    },
    /* more skills here */
  ],
  jobs: [
    /* more jobs here */
    {
      company: 'CWC Web Development',
      begin: {
        month: 'Jun',
        year: '2012',
      },
      duration: null,
      occupation: 'Full Stack Freelance Web Developer',
      description:
        'Provide both front and back end website development as required by customers covering a wide range of languages, libraries and frameworks over an 11 year period. Sadly, I have little time for freelance work these days though.',
    },
    {
      company: 'US Foods',
      begin: {
        month: 'Apr',
        year: '2022',
      },
      duration: '1 yrs - 11 mos',
      occupation: 'Software Engineer',
      description:
        'Contributed to the development of the ecommerce application used by US Foods to manage their supply chain logistics, operations and stock necessary to run their businesses in the food industry.',
    },
    {
      company: 'RVA Web',
      begin: {
        month: 'Jan',
        year: '2016',
      },
      duration: '5 yr - 7 mos',
      occupation: 'Senior Software Engineer',
      description:
        'Managed day-to-day development operations, including Agile Methodologies, project prioritization, workload allocation, and team relations.',
    },
    {
      company: 'EDGE Web Solutions LLC.',
      begin: {
        month: 'Oct',
        year: '2012',
      },
      duration: '1 yr - 8 mos',
      occupation: 'Web developer',
      description:
        'Development and maintenance, corrective and preventive, of web applications for a wide customer base specializing in both small and large business solutions.',
    },
    {
      company: 'U.S. Navy',
      begin: {
        month: 'Jun',
        year: '2008',
      },
      duration: '4 yrs',
      occupation: '3rd Class Petty Officer',
      description:
        'Both operated and performed preventative and scheduled maintenance on analog and digital systems used for the operation of all passive and active sonar systems on a surface warfare vessel.',
    },
  ],
  about: {
    image: 'images/fam1.jpeg',
    summary: 'Me, My Family and I...',
  },
  hire: {
    image: 'images/computer.jpg',
    summary: 'My professional agenda...',
  },
  portfolio: [
    {
      image: '/images/moxe.jpeg',
      description:
        'App used by US Foods Vendors and Customers for managing orders, shipments, ecommerce, inventory, etc. On mobile and Web',
      url: 'https://www.usfoods.com/our-services/easy-ordering.html',
    },
    {
      image: '/images/truehomes-screenshots.png',
      description:
        'Website and React app for local inspection company with a fake API backend to protect the users and their customers/clients/partners',
      // 'Website and React app for local inspection company with a fake API backend to protect the users and their customers/clients/partners (user: cwcwebdevelopment@gmail.com pass: Cam1218!)',
      url: 'http://truehome-portfolio.s3-website-us-east-1.amazonaws.com',
    },
    {
      image: '/images/hhh-screenshots.png',
      description:
        'Marketing site with CMS and a business management and payroll app connected to Square API.',
      url: 'https://hhhrva.com',
    },
    {
      image: '/images/algoanon-screenshots.png',
      description:
        'Open Source blog written with a custom built CMS utilizing a MEAN stack.',
      url: 'https://algoanon.com',
    },
    {
      image: '/images/barricaid-screenshots.png',
      description: 'Wordpress site for local business startup',
      url: 'https://barricaidtech.com/',
    },
    {
      image: '/images/retail-data-screenshots.png',
      description:
        'Small app showcasing a recently written Angular Component Library. Unfortunately unable to display too much of this project.',
      // url: 'http://angular-library.s3-website-us-east-1.amazonaws.com',
    },
    {
      image: '/images/dominion-screenshots.png',
      description:
        "App used by dominion employees in the field to find and update outage data. Unfortunately a false API couldn't be written for this app.",
      url: 'https://www.dominionenergy.com/virginia/report-outage-or-emergency',
    },
    /* more portfolio items here */
  ],
  resumeFile: [
    {
      file: '/images/resume-1.jpg',
    },
    {
      file: '/images/resume-2.jpg',
    },
  ],
  social: {
    facebook: 'https://facebook.com/corey.waldrop',
    twitter: 'https://twitter.com/WaldropCorey',
    linkedin: 'https://www.linkedin.com/in/corey-waldrop',
    github: 'https://github.com/rad10head7',
    email: 'cwcwebdevelopment@gmail.com',
  },
  siteUrl: 'https://coreywaldrop.com',
  pathPrefix: '/', // Note: it must *not* have a trailing slash.
  siteCover: '/images/mountains.jpg',
  googleAnalyticsId: 'UA-186144695-1',
  wrapper_background_color: '#FFFFFF',
  background_color: '#e1e1e1',
  theme_color: '#1F5488', //was 25303B
  fontColor: '#000000cc',
  enableDarkmode: true, // If true, enables dark mode switch
  display: 'minimal-ui',
  icon: 'src/assets/corey.png',
  headerLinks: [
    {
      label: 'Home',
      url: '/',
    },
    {
      label: 'About',
      url: '/about',
    },
    {
      label: 'Hire',
      url: '/hire',
    },
    {
      label: 'Blog',
      url: '/blog',
    },
    {
      label: 'Portfolio',
      url: '/portfolio',
    },
    {
      label: 'Resume',
      url: '/resume',
    },
  ],
}
